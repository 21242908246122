import axios from "axios";
import _ from "lodash";
import Response from "@/models/Response";

async function getSheet(key: string, sheet = "Sheet1") {
    const apiKey = process.env.VUE_APP_SPREADSHEET_API_KEY;
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${key}/values/${sheet}?key=${apiKey}`;

    const result = await axios.get(url);

    if (result.status !== 200)
        throw Error("Could not get spreadsheet, is it published?");

    return result.data.values as string[][];
}

function getResponses(responses: string[][], colId: number, colContent: number, rowStart = 1) {
    const results = new Array<Response>();

    if (rowStart > 1)
        responses = _.slice(responses, rowStart - 1);

    _.forEach(responses, (value) => {
        const id = value[colId];
        const content = value[colContent];

        if (content && content.length > 0)
            results.push({ id, content, category: 0 });
    });

    return results;
}

export default {
    getSheet,
    getResponses
};
