import { createApp } from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/store";
import SvgIcon from "@jamescoyle/vue-icon";

import "./main.css";

const app = createApp(App);

app.component("svg-icon", SvgIcon);
app.use(store);
app.use(router);
app.mount("#app");