
import ResponseService from "@/services/responseService";
import Response from "@/models/Response";
import draggable from "vuedraggable";
import _ from "lodash";
import
{
    mdiEmoticonAngry,
    mdiEmoticonAngryOutline,
    mdiEmoticonConfused,
    mdiEmoticonConfusedOutline,
    mdiEmoticonNeutral,
    mdiEmoticonNeutralOutline,
    mdiEmoticonHappy,
    mdiEmoticonHappyOutline,
    mdiEmoticonExcited,
    mdiEmoticonExcitedOutline,
    mdiEmoticonCool,
    mdiEmoticonCoolOutline,
    mdiCheck,
    mdiCog,
    mdiChevronRight
} from "@mdi/js";
import
{
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@headlessui/vue";

import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { ref, defineComponent, reactive, onMounted, watchEffect, watch, watchSyncEffect } from "vue";
import { useRoute } from "vue-router";

interface Configuration {
    name: string;
    sheet: string;
    rowStart: number;
    colKey: number;
    colValue: number;
}

const scrollIntoViewSmoothly =
    "scrollBehavior" in document.documentElement.style
        ? scrollIntoView
        : smoothScrollIntoView;

const configurations: Configuration[] = [
    {
        name: "CSC (S5)",
        sheet: "Sheet1",
        rowStart: 1,
        colKey: 4,
        colValue: 6
    },
    {
        name: "AnEpik's CSC",
        sheet: "voting",
        rowStart: 3,
        colKey: 5,
        colValue: 6
    }
];

export default defineComponent({
    name: "Home",
    components: {
        draggable,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },
    setup() {
        const route = useRoute();

        const key = ref(process.env.VUE_APP_SPREADSHEET_ID);
        const sheet_name = ref("");
        const sheet_col_id = ref(-1);
        const sheet_col_content = ref(-1);
        const sheet_row_start = ref(-1);
        const responses = ref<Response[]>([]);
        const focused = ref(false);
        const streamlined = ref(false);
        const selected = ref<Response>();
        const selected_config = ref<Configuration>(configurations[0]);
        const configPanel = ref();

        const goNext = (reverse = false, fromKey = false) => {
            if (!selected.value) {
                if (responses.value.length > 0)
                    selected.value = responses.value[reverse ? responses.value.length - 1 : 0];
                else return;
            } else {
                const ci = _.findIndex(responses.value, (v) => v.id === selected.value?.id);

                if (reverse && ci === 0)
                    selected.value = responses.value[responses.value.length - 1];
                else if (!reverse && ci >= responses.value.length - 1)
                    selected.value = responses.value[0];
                else selected.value = responses.value[ci + (reverse ? -1 : 1)];
            }

            if (streamlined.value && selected.value?.category !== 0) {
                const any = _.some(responses.value, (v) => v.category === 0);

                if (any) goNext(reverse);
                else streamlined.value = false;
            }

            const target = document.getElementById(`response-${selected.value.id}`);

            if (!target)
                return;

            scrollIntoViewSmoothly(target, {
                scrollMode: fromKey ? "always" : "if-needed",
                block: "center",
                behavior: "smooth"
            });
        };

        const setHistoryState = (params: any) => {
            params = Object.assign(params, { document: key.value });
            history.pushState({}, "", route.path + "?" + Object.keys(params).map(k => {
                return (encodeURIComponent(k) + "=" + encodeURIComponent(params[k]));
            }).join("&"));
        };

        const useConfiguration = (configuration: Configuration) => {
            sheet_name.value = configuration.sheet;
            sheet_row_start.value = configuration.rowStart;
            sheet_col_id.value = configuration.colKey;
            sheet_col_content.value = configuration.colValue;

            setHistoryState({
                config: _.findIndex(configurations, ["name", selected_config.value.name])
            });
        };

        const loadSheet = async () => {
            const sheet = await ResponseService.getSheet(key.value, sheet_name.value);
            const formatted = ResponseService.getResponses(sheet, sheet_col_id.value, sheet_col_content.value, sheet_row_start.value);

            responses.value = formatted;

            goNext();
        };

        const dragstart = (evt: DragEvent) => {
            const dragElement = document.createElement("span");
            dragElement.setAttribute("style", "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;");
            document.body.appendChild(dragElement);
            evt.dataTransfer?.setDragImage(dragElement, 0, 0);
        };

        const setCategory = (response: Response, category: number, fromKey = false) => {
            response.category = category;

            if (selected.value && selected.value.id === response.id)
                goNext(false, fromKey);
        };

        const categorySort = () => {
            responses.value = _.sortBy(responses.value, (v) => -v.category);
        };

        const streamlineToggle = () => {
            streamlined.value = !streamlined.value;

            if (focused.value && streamlined.value)
                focused.value = false;

            if (streamlined.value && selected.value && selected.value.category !== 0)
                goNext();
        };

        const focusToggle = () => {
            focused.value = !focused.value;

            if (focused.value && streamlined.value)
                streamlined.value = false;
        };

        document.addEventListener("keyup", (evt) => {
            if (!evt) evt = window.event as KeyboardEvent;

            if (!selected.value)
                return;

            if (document.activeElement && document.activeElement instanceof HTMLInputElement)
                return;

            const k = evt.key;

            switch (k) {
            case "a":
                goNext(true, true);
                break;
            case "z":
                goNext(false, true);
                break;
            case "1":
                setCategory(selected.value, 1, true);
                break;
            case "2":
                setCategory(selected.value, 2, true);
                break;
            case "3":
                setCategory(selected.value, 3, true);
                break;
            case "4":
                setCategory(selected.value, 4, true);
                break;
            case "5":
                setCategory(selected.value, 5, true);
                break;
            case "6":
                setCategory(selected.value, 6, true);
                break;
            }

        }, false);

        const drag = ref(false);

        watchSyncEffect(() => {
            setHistoryState({
                sheet: sheet_name.value,
                key: sheet_col_id.value,
                value: sheet_col_content.value,
                skip: sheet_row_start.value
            });
        });

        onMounted(() => {
            const q = {
                config: route.query.config,
                document: route.query.document,
                sheet: route.query.sheet,
                key: route.query.key,
                value: route.query.value,
                skip: route.query.skip
            };

            if (q.document)
                key.value = q.document.toString();




            if (q.config) {
                selected_config.value = configurations[+q.config.toString()];
                useConfiguration(selected_config.value);
            } else {
                selected_config.value = configurations[0];
                useConfiguration(selected_config.value);

                if (q.sheet)
                    sheet_name.value = q.sheet.toString();

                if (q.key)
                    sheet_col_id.value = +q.key.toString();

                if (q.value)
                    sheet_col_content.value = +q.value.toString();

                if (q.skip)
                    sheet_row_start.value = +q.skip.toString();
            }

            loadSheet();
        });

        return reactive({
            key,
            sheet_name,
            sheet_col_id,
            sheet_col_content,
            sheet_row_start,
            configPanel,
            responses,
            configurations,
            focused,
            streamlined,
            selected,
            selected_config,
            drag,
            dragOptions: {
                group: "votes",
                ghostClass: "ghost",
                animation: 200
            },
            icons: {
                angry: {
                    on: mdiEmoticonAngry,
                    off: mdiEmoticonAngryOutline
                },
                confused: {
                    on: mdiEmoticonConfused,
                    off: mdiEmoticonConfusedOutline
                },
                neutral: {
                    on: mdiEmoticonNeutral,
                    off: mdiEmoticonNeutralOutline
                },
                happy: {
                    on: mdiEmoticonHappy,
                    off: mdiEmoticonHappyOutline
                },
                excited: {
                    on: mdiEmoticonExcited,
                    off: mdiEmoticonExcitedOutline
                },
                cool: {
                    on: mdiEmoticonCool,
                    off: mdiEmoticonCoolOutline
                },
                selected: mdiChevronRight,
                settings: mdiCog,
                check: mdiCheck
            },

            categorySort,
            setCategory,
            streamlineToggle,
            focusToggle,
            dragstart,

            loadSheet,
            useConfiguration
        });
    }
});
