<template>
    <div>
        <Disclosure v-slot="{ open }">
            <DisclosureButton class="w-full text-left focus-within:outline-none" @click="open = !open;">
                <div
                    class="flex p-3 px-5 bg-white text-xl font-bold text-gray-900 shadow">
                    <span class="flex-grow">Vote!</span>
                    <svg-icon type="mdi" :path="icons.settings"
                              :size="28"
                              class="transform ease-in-out duration-300 text-gray-600"
                              :rotate="open ? 270 : 0"/>
                </div>
            </DisclosureButton>
            <DisclosurePanel :unmount="false" static>
                <div
                    ref="configPanel"
                    :class="['bg-white z-20 relative transition-all duration-500 max-h-0 overflow-hidden', open ? 'shadow-xl' : 'shadow-none']"
                    :style="open ? 'max-height: ' + configPanel.scrollHeight * 1.25 + 'px' : ''">
                    <div class="max-w-full text-sm text-gray-500 p-3 sm:p-5 pt-0 sm:pt-0">
                        <h4 class="font-medium text-gray-700">General</h4>
                        <p class="mt-1">
                            To select a voting sheet you must input the document identifier from a Google Spreadsheet,
                            you can locate the document identifier between <strong>/d/</strong> and the following
                            <strong>/</strong> in th e document's URL.
                        </p>
                        <p class="mt-1">
                            If the service fails to load the document you can attempt an alternate method by making your
                            own copy of the document and using <strong>File > Publish to Web</strong> to make it
                            accessible by the service.
                        </p>
                        <h4 class="mt-1.5 font-medium text-gray-700">CSC</h4>
                        <p class="mt-1">
                            If the most current CSC vote sheet has not been preloaded you can manually load it here
                            using the instructions found above under <strong>General</strong>, you should not have to
                            alter any parameters other than the Document Identifier.
                        </p>
                        <h4 class="mt-1.5 font-medium text-gray-700">Other</h4>
                        <p class="mt-1">
                            If you want to use this tool with a different vote sheet you can!
                        </p>
                        <ul class="list-decimal list-inside ml-4 mt-2">
                            <li>
                                <strong>Check for existing configurations in the 'Use Config' dropdown</strong>, if your
                                competition is already accounted for you can select it, click 'Use Config', and proceed
                                to the final step.
                            </li>
                            <li>
                                <strong>Sheet</strong> should contain the name of the Sheet Tab that the responses are
                                on.
                            </li>
                            <li>
                                <strong>Starting Row</strong> should contain the row number (starting with 1, not 0) the
                                responses actually start at.
                            </li>
                            <li>
                                <strong>Key Column</strong> should contain the column number (starting with 0) for the
                                column the response's identifier is in.
                            </li>
                            <li>
                                <strong>Value Column</strong> should contain the column number (starting with 0) for the
                                column the response is in.
                            </li>
                            <li>
                                <strong>Click 'Load Sheet'</strong> once you've entered the correct parameters for your
                                sheet.
                            </li>
                        </ul>
                    </div>
                    <div class="grid grid-cols-2 gap-6 max-w-full p-3 sm:p-5">
                        <div class="col-span-full">
                            <label for="spreadsheet_key" class="block text-sm font-medium text-gray-700">
                                Document Identifier
                            </label>
                            <div class="flex rounded-md shadow-sm mt-1">
                                <div class="flex relative items-stretch flex-grow focus-within:z-10">
                            <span
                                class="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
                                https://docs.google.com/spreadsheets/d/
                            </span>
                                    <input type="text" v-model="key" name="spreadsheet_key" id="spreadsheet_key"
                                           class="flex-1 block w-full min-w-0 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 rounded-r-md sm:text-sm">
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 sm:col-span-1">
                            <label for="spreadsheet_sheet" class="block text-sm font-medium text-gray-700">
                                Sheet
                            </label>
                            <input type="text" v-model="sheet_name" name="spreadsheet_sheet" id="spreadsheet_sheet"
                                   class="mt-1 rounded-md w-full min-w-0 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        </div>
                        <div class="col-span-2 sm:col-span-1">
                            <label for="spreadsheet_sheet_row_start" class="block text-sm font-medium text-gray-700">
                                Starting Row (Starting with 1)
                            </label>
                            <input type="text" v-model="sheet_row_start" name="spreadsheet_sheet_row_start"
                                   id="spreadsheet_sheet_row_start"
                                   class="mt-1 rounded-md w-full min-w-0 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        </div>
                        <div class="col-span-2 sm:col-span-1">
                            <label for="spreadsheet_sheet_col_id" class="block text-sm font-medium text-gray-700">
                                Key Column (A = 0, B = 1, ...)
                            </label>
                            <input type="text" v-model="sheet_col_id" name="spreadsheet_sheet_col_id"
                                   id="spreadsheet_sheet_col_id"
                                   class="mt-1 rounded-md w-full min-w-0 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        </div>
                        <div class="col-span-2 sm:col-span-1">
                            <label for="spreadsheet_sheet_col_content" class="block text-sm font-medium text-gray-700">
                                Value Column (A = 0, B = 1, ...)
                            </label>
                            <input type="text" v-model="sheet_col_content" name="spreadsheet_sheet_col_content"
                                   id="spreadsheet_sheet_col_content"
                                   class="mt-1 rounded-md w-full min-w-0 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        </div>

                        <div class="col-span-2 flex flex-wrap sm:justify-end">
                            <Listbox as="div" v-model="selected_config" class="flex-grow sm:flex-grow-0 sm:mr-4">
                                <ListboxLabel class="sr-only">
                                    Change Vote Configuration
                                </ListboxLabel>
                                <div class="relative">
                                    <div class="inline-flex shadow-sm rounded-md divide-x divide-indigo-600 w-full">
                                        <div
                                            class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600 w-full">
                                            <button @click="useConfiguration(selected_config)"
                                                    class="relative inline-flex items-center bg-indigo-600 py-2 px-4 rounded-l-md shadow-sm text-white text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-indigo-500 w-full">
                                                Use '{{ selected_config.name }}' Config
                                            </button>
                                        </div>
                                        <ListboxButton v-slot="{ open }"
                                                       class="relative inline-flex items-center bg-indigo-600 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-indigo-500">
                                            <span class="sr-only">Change Vote Configuration</span>
                                            <svg-icon type="mdi" :path="icons.selected" :size="20"
                                                      class="transform ease-in-out duration-100"
                                                      :rotate="open ? -90 : 0"/>
                                        </ListboxButton>
                                    </div>

                                    <transition
                                        enter-active-class="transition ease-in duration-600"
                                        leave-active-class="transition ease-out duration-600"
                                        enter-from-class="opacity-0"
                                        leave-from-class="opacity-100"
                                        enter-to-class="opacity-100"
                                        leave-to-class="opacity-0">
                                        <ListboxOptions
                                            class="shadow-md origin-bottom-right absolute bottom-10 right-0 z-30 mt-2 w-72 rounded-md overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <ListboxOption as="template" v-for="config in configurations"
                                                           :key="config.name" :value="config"
                                                           v-slot="{ active, selected }">
                                                <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-pointer select-none relative p-4 text-sm']">
                                                    <div class="flex flex-col">
                                                        <div class="flex justify-between">
                                                            <p :class="selected ? 'font-semibold' : 'font-normal'">
                                                                {{ config.name }}
                                                            </p>
                                                            <span v-if="selected"
                                                                  :class="active ? 'text-white' : 'text-indigo-600'">
                                                    <svg-icon type="mdi" :path="icons.check" :size="20" class="h-5 w-5"
                                                              aria-hidden="true"/>
                                                </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                </div>
                            </Listbox>

                            <div class="flex-grow sm:flex-grow-0 w-full sm:w-auto mt-6 sm:mt-0">
                                <button @click="loadSheet()"
                                        class="sm:w-auto px-4 py-2 -ml-px space-x-2 text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-indigo-500 w-full">
                                    <span>Load Sheet</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </DisclosurePanel>
        </Disclosure>
    </div>

    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="px-4 py-8 pt-4 sm:px-0">
            <div v-if="responses.length > 0" class="mt-5 bg-white shadow sm:rounded-md">
                <div class="p-4 text-sm text-red-700 bg-red-100 rounded" role="alert">
                    <span class="font-medium">Hey, listen!</span> Do not forget to manually sort your votes after categorizing them or your vote will be invalid.
                </div>
                <div class="px-4 py-5 select-all sm:p-6">
                    Votes:
                    <span v-for="(response, index) in responses" :key="response.id">
                        {{ response.id }}<span v-if="index < responses.length - 1">, </span>
                    </span>
                </div>
            </div>

            <div class="mt-5 bg-white shadow sm:rounded-md">
                <div class="flex items-center justify-between px-4 py-5">
                    <div class="flex-1">
                        <button @click="categorySort()" type="button"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-indigo-500">
                            Sort by Category
                        </button>
                    </div>
                    <div class="flex items-center flex-shrink-0 mr-3">
                        <button @click="focusToggle()" type="button" aria-pressed="false" aria-labelledby="toggleLabel"
                                :class="`${focused ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`">
                            <span class="sr-only">Use setting</span>
                            <span aria-hidden="true"
                                  :class="`${focused ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`"></span>
                        </button>
                        <span class="ml-3" id="toggleLabel">
                            <span class="text-sm font-medium text-gray-900">Focus Mode</span>
                        </span>
                    </div>
                    <div class="flex items-center flex-shrink-0">
                        <button @click="streamlineToggle()" type="button" aria-pressed="false"
                                aria-labelledby="toggleLabel"
                                :class="`${streamlined ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`">
                            <span class="sr-only">Use setting</span>
                            <span aria-hidden="true"
                                  :class="`${streamlined ? 'translate-x-5' : 'translate-x-0'} inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`"></span>
                        </button>
                        <span class="ml-3" id="toggleLabel">
                            <span class="text-sm font-medium text-gray-900">Hide Categorized Responses</span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <draggable
                    v-model="responses"
                    item-key="id"
                    tag="transition-group"
                    :component-data="{
                        tag: 'div',
                        type: 'transition-group',
                        name: !drag ? 'flip-list' : null
                    }"
                    v-bind="dragOptions"
                    @start="drag = true"
                    @end="drag = false">
                    <template #item="{element}">
                        <div
                            @click="selected = element"
                            @dragstart="dragstart($event);selected = element">
                            <div
                                v-show="(!streamlined && !focused) || (streamlined && element.category === 0) || (focused && selected && selected.id === element.id)"
                                :id="`response-${element.id}`"
                                :class="`border-b border-gray-200 cursor-move flex space-x-2 items-center text-sm bg-white hover:bg-gray-50 sm:p-4 ${selected && selected.id === element.id ? 'my-2 border-blue-500 z-50 shadow-lg scale-102 border-l-4' : 'shadow-sm' } transform transition-all`">
                                <div class="flex-1 whitespace-pre-wrap transition-all sm:flex">
                                    {{ element.id }}. {{ element.content }}
                                </div>
                                <div class="flex-shrink-0 transition-all">
                                    <svg-icon @click.stop="setCategory(element, 1)" type="mdi"
                                              :path="element.category === 1 ? icons.angry.on : icons.angry.off"
                                              :size="32"
                                              :class="`inline-block text-red-500 ${element.category === 1 ? 'opacity-100' : 'opacity-50'} cursor-pointer hover:opacity-75`"/>
                                    <svg-icon @click.stop="setCategory(element, 2)" type="mdi"
                                              :path="element.category === 2 ? icons.confused.on : icons.confused.off"
                                              :size="32"
                                              :class="`inline-block text-orange-500 ${element.category === 2 ? 'opacity-100' : 'opacity-50'} cursor-pointer hover:opacity-75`"/>
                                    <svg-icon @click.stop="setCategory(element, 3)" type="mdi"
                                              :path="element.category === 3 ? icons.neutral.on : icons.neutral.off"
                                              :size="32"
                                              :class="`inline-block text-yellow-400 ${element.category === 3 ? 'opacity-100' : 'opacity-50'} cursor-pointer hover:opacity-75`"/>
                                    <svg-icon @click.stop="setCategory(element, 4)" type="mdi"
                                              :path="element.category === 4 ? icons.happy.on : icons.happy.off"
                                              :size="32"
                                              :class="`inline-block text-green-500 ${element.category === 4 ? 'opacity-100' : 'opacity-50'} cursor-pointer hover:opacity-75`"/>
                                    <svg-icon @click.stop="setCategory(element, 5)" type="mdi"
                                              :path="element.category === 5 ? icons.excited.on : icons.excited.off"
                                              :size="32"
                                              :class="`inline-block text-blue-500 ${element.category === 5 ? 'opacity-100' : 'opacity-50'} cursor-pointer hover:opacity-75`"/>
                                    <svg-icon @click.stop="setCategory(element, 6)" type="mdi"
                                              :path="element.category === 6 ? icons.cool.on : icons.cool.off" :size="32"
                                              :class="`inline-block text-purple-500 ${element.category === 6 ? 'opacity-100' : 'opacity-50'} cursor-pointer hover:opacity-75`"/>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>

<style scoped>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    @apply bg-gray-200;
}
</style>

<script lang="ts">
import ResponseService from "@/services/responseService";
import Response from "@/models/Response";
import draggable from "vuedraggable";
import _ from "lodash";
import
{
    mdiEmoticonAngry,
    mdiEmoticonAngryOutline,
    mdiEmoticonConfused,
    mdiEmoticonConfusedOutline,
    mdiEmoticonNeutral,
    mdiEmoticonNeutralOutline,
    mdiEmoticonHappy,
    mdiEmoticonHappyOutline,
    mdiEmoticonExcited,
    mdiEmoticonExcitedOutline,
    mdiEmoticonCool,
    mdiEmoticonCoolOutline,
    mdiCheck,
    mdiCog,
    mdiChevronRight
} from "@mdi/js";
import
{
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
} from "@headlessui/vue";

import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { ref, defineComponent, reactive, onMounted, watchEffect, watch, watchSyncEffect } from "vue";
import { useRoute } from "vue-router";

interface Configuration {
    name: string;
    sheet: string;
    rowStart: number;
    colKey: number;
    colValue: number;
}

const scrollIntoViewSmoothly =
    "scrollBehavior" in document.documentElement.style
        ? scrollIntoView
        : smoothScrollIntoView;

const configurations: Configuration[] = [
    {
        name: "CSC (S5)",
        sheet: "Sheet1",
        rowStart: 1,
        colKey: 4,
        colValue: 6
    },
    {
        name: "AnEpik's CSC",
        sheet: "voting",
        rowStart: 3,
        colKey: 5,
        colValue: 6
    }
];

export default defineComponent({
    name: "Home",
    components: {
        draggable,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },
    setup() {
        const route = useRoute();

        const key = ref(process.env.VUE_APP_SPREADSHEET_ID);
        const sheet_name = ref("");
        const sheet_col_id = ref(-1);
        const sheet_col_content = ref(-1);
        const sheet_row_start = ref(-1);
        const responses = ref<Response[]>([]);
        const focused = ref(false);
        const streamlined = ref(false);
        const selected = ref<Response>();
        const selected_config = ref<Configuration>(configurations[0]);
        const configPanel = ref();

        const goNext = (reverse = false, fromKey = false) => {
            if (!selected.value) {
                if (responses.value.length > 0)
                    selected.value = responses.value[reverse ? responses.value.length - 1 : 0];
                else return;
            } else {
                const ci = _.findIndex(responses.value, (v) => v.id === selected.value?.id);

                if (reverse && ci === 0)
                    selected.value = responses.value[responses.value.length - 1];
                else if (!reverse && ci >= responses.value.length - 1)
                    selected.value = responses.value[0];
                else selected.value = responses.value[ci + (reverse ? -1 : 1)];
            }

            if (streamlined.value && selected.value?.category !== 0) {
                const any = _.some(responses.value, (v) => v.category === 0);

                if (any) goNext(reverse);
                else streamlined.value = false;
            }

            const target = document.getElementById(`response-${selected.value.id}`);

            if (!target)
                return;

            scrollIntoViewSmoothly(target, {
                scrollMode: fromKey ? "always" : "if-needed",
                block: "center",
                behavior: "smooth"
            });
        };

        const setHistoryState = (params: any) => {
            params = Object.assign(params, { document: key.value });
            history.pushState({}, "", route.path + "?" + Object.keys(params).map(k => {
                return (encodeURIComponent(k) + "=" + encodeURIComponent(params[k]));
            }).join("&"));
        };

        const useConfiguration = (configuration: Configuration) => {
            sheet_name.value = configuration.sheet;
            sheet_row_start.value = configuration.rowStart;
            sheet_col_id.value = configuration.colKey;
            sheet_col_content.value = configuration.colValue;

            setHistoryState({
                config: _.findIndex(configurations, ["name", selected_config.value.name])
            });
        };

        const loadSheet = async () => {
            const sheet = await ResponseService.getSheet(key.value, sheet_name.value);
            const formatted = ResponseService.getResponses(sheet, sheet_col_id.value, sheet_col_content.value, sheet_row_start.value);

            responses.value = formatted;

            goNext();
        };

        const dragstart = (evt: DragEvent) => {
            const dragElement = document.createElement("span");
            dragElement.setAttribute("style", "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;");
            document.body.appendChild(dragElement);
            evt.dataTransfer?.setDragImage(dragElement, 0, 0);
        };

        const setCategory = (response: Response, category: number, fromKey = false) => {
            response.category = category;

            if (selected.value && selected.value.id === response.id)
                goNext(false, fromKey);
        };

        const categorySort = () => {
            responses.value = _.sortBy(responses.value, (v) => -v.category);
        };

        const streamlineToggle = () => {
            streamlined.value = !streamlined.value;

            if (focused.value && streamlined.value)
                focused.value = false;

            if (streamlined.value && selected.value && selected.value.category !== 0)
                goNext();
        };

        const focusToggle = () => {
            focused.value = !focused.value;

            if (focused.value && streamlined.value)
                streamlined.value = false;
        };

        document.addEventListener("keyup", (evt) => {
            if (!evt) evt = window.event as KeyboardEvent;

            if (!selected.value)
                return;

            if (document.activeElement && document.activeElement instanceof HTMLInputElement)
                return;

            const k = evt.key;

            switch (k) {
            case "a":
                goNext(true, true);
                break;
            case "z":
                goNext(false, true);
                break;
            case "1":
                setCategory(selected.value, 1, true);
                break;
            case "2":
                setCategory(selected.value, 2, true);
                break;
            case "3":
                setCategory(selected.value, 3, true);
                break;
            case "4":
                setCategory(selected.value, 4, true);
                break;
            case "5":
                setCategory(selected.value, 5, true);
                break;
            case "6":
                setCategory(selected.value, 6, true);
                break;
            }

        }, false);

        const drag = ref(false);

        watchSyncEffect(() => {
            setHistoryState({
                sheet: sheet_name.value,
                key: sheet_col_id.value,
                value: sheet_col_content.value,
                skip: sheet_row_start.value
            });
        });

        onMounted(() => {
            const q = {
                config: route.query.config,
                document: route.query.document,
                sheet: route.query.sheet,
                key: route.query.key,
                value: route.query.value,
                skip: route.query.skip
            };

            if (q.document)
                key.value = q.document.toString();




            if (q.config) {
                selected_config.value = configurations[+q.config.toString()];
                useConfiguration(selected_config.value);
            } else {
                selected_config.value = configurations[0];
                useConfiguration(selected_config.value);

                if (q.sheet)
                    sheet_name.value = q.sheet.toString();

                if (q.key)
                    sheet_col_id.value = +q.key.toString();

                if (q.value)
                    sheet_col_content.value = +q.value.toString();

                if (q.skip)
                    sheet_row_start.value = +q.skip.toString();
            }

            loadSheet();
        });

        return reactive({
            key,
            sheet_name,
            sheet_col_id,
            sheet_col_content,
            sheet_row_start,
            configPanel,
            responses,
            configurations,
            focused,
            streamlined,
            selected,
            selected_config,
            drag,
            dragOptions: {
                group: "votes",
                ghostClass: "ghost",
                animation: 200
            },
            icons: {
                angry: {
                    on: mdiEmoticonAngry,
                    off: mdiEmoticonAngryOutline
                },
                confused: {
                    on: mdiEmoticonConfused,
                    off: mdiEmoticonConfusedOutline
                },
                neutral: {
                    on: mdiEmoticonNeutral,
                    off: mdiEmoticonNeutralOutline
                },
                happy: {
                    on: mdiEmoticonHappy,
                    off: mdiEmoticonHappyOutline
                },
                excited: {
                    on: mdiEmoticonExcited,
                    off: mdiEmoticonExcitedOutline
                },
                cool: {
                    on: mdiEmoticonCool,
                    off: mdiEmoticonCoolOutline
                },
                selected: mdiChevronRight,
                settings: mdiCog,
                check: mdiCheck
            },

            categorySort,
            setCategory,
            streamlineToggle,
            focusToggle,
            dragstart,

            loadSheet,
            useConfiguration
        });
    }
});
</script>
